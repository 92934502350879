import React from 'react';
import {graphql } from 'gatsby'
import "slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import BreadCrumbs from '../Components/PropertyDetails/BackButton/BreadCrumbs'
// import { CareerDetail } from '../queries/common_use_query';
import Trainee from '../Components/OfficeDetails/Trainee/Trainee';
import SEO from '../Components/Seo/seo';
import Connect from '../Components/CarrierLanding/Connect/Connect';
const CareerDetails = (props) => {
    //const {loading, error, data} = CareerDetail(props.url);
    const GQLPage = props.data.glstrapi?.career;
    return (
        <React.Fragment>
            <div className="wrapper property-wrap team-details-page career-details-page">
                <div className="inner-header"><Header /></div>
                           
                        <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Job_Opening} description={GQLPage.Meta_Description} />             
                        <BreadCrumbs alias="careers" baselabel="careers" baseurl={"/about/careers/"} itemlabel={GQLPage.Job_Opening}/>
                        <Trainee item={GQLPage}/>
                        <Connect member={GQLPage.Select_Member} jobname={GQLPage.Job_Opening} type="hidden" toemail={GQLPage.Contact_Email}/>
                    
                <Footer popularSearch="Popular_Search_Static_Common" />
            </div>
        </React.Fragment>
    )
}
export default CareerDetails;

export const careerdetailQuery = graphql`
query CareersQuery($id: ID!) {
  glstrapi {
    career(id: $id) {  
        id
        Job_Opening
        Meta_Title
        Meta_Description
        URL
        Listing_Intro
        Contact_Email
        Type
        Salary
        Location
        Description
        Select_Member {
            id
            URL
            Staff_Image {
              url
              alternativeText
            }
            imagetransforms
            Name
            Designation
            Phone_Number
        } 
    }
}
}`

