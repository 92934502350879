import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Card } from "react-bootstrap";
import Team01 from "../../../images/Office/team-img01.jpg";
import PlayVideo from "../../../Components/Play/PlayVideo";
import StaticRightBlock from '../../../Components/PropertyDetails/RightBlock/StaticRightMember'

import Team001 from "../../../images/Office/training02.png";
import Img02 from "../../../images/Office/contact-img1600.png";
import ScrollAnimation from "react-animate-on-scroll";
import "./Trainee.scss";
import parse from 'html-react-parser';
import SocialShare from '../../../../src/share'
import $ from 'jquery'
const Trainee = (props) => {
  function gotohash(event) {
    var thishash = event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top - 120
    }, 1000);
  }
  const [isPlay, setPlay] = useState(false);
  var item= props.item;
  return (
    <React.Fragment>
      <section className="trainee">        
        <Container>
          <Row>
            <Col>
              <div className="info-section">
                
                  {/* <div className="team-img">
                  <StaticRightBlock staff={item.Select_Member}/>
                  </div> */}
                
                
                  <div className="consultant">
                    <h1>{item.Job_Opening}</h1>
                   
                    <ul className="details">
                      <li className="trainee-wrapper">
                        <span>Location:</span>
                        <span>{item.Location}</span>
                      </li>

                      {/* <li className="trainee-wrapper">
                        <span>Salary:</span>
                        <span>{item.Salary}</span>
                      </li> */}

                      <li className="trainee-wrapper">
                        <span>Type:</span>
                        <span>{item.Type}</span>
                      </li>
                    </ul>

                    <div className="btn-wrapper">
                      <a href="javascript:;"  onClick={() => gotohash('#send-cv')} className="btn btn-primary">
                        Apply for this job
                      </a>
                      {/* <Link to="#" className="btn btn-primary share">
                        Share Job
                      </Link> */}
                      <SocialShare class="btn btn-primary share" name="Share Job"/>
                    </div>
                    <div className="about">
                        {parse(item.Description)}
                    </div>
                    <div className="btn-wrapper career-btn-two">
                    <a href="javascript:;"  onClick={() => gotohash('#send-cv')} className="btn btn-primary">
                        Apply for this job
                      </a>
                      <SocialShare class="btn btn-primary share" name="Share Job"/>
                      {/* <Link to="#" className="btn btn-primary share">
                        Share Job
                      </Link> */}
                    </div>
                  </div>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId="V8ksSGxo6no"
        isAutoPlay={1}
      />
    </React.Fragment>
  );
};
export default Trainee;
